<template>
  <div class="form-container">
    <ValidationObserver ref="form">
      <!-- 기타입력 폼 -->
      <form>
        <fieldset>
          <legend>{{$t('content.accelerating.academy.Common.txt06')}}</legend>
          <div class="align-right">
            <!-- *는 필수입력 항목입니다. -->
            <em class="required-mark">*</em>{{ $t('content.networking.CompanyInfo.txt53') }}
          </div>
          <ul>
            <!-- 파일첨부 -->
            <template
              v-if="accApplyPgmMngDetail.comRegNoFileYn || accApplyPgmMngDetail.stockholderFileYn ||
                accApplyPgmMngDetail.registerFileYn || accApplyPgmMngDetail.propertyFileYn ||
                accApplyPgmMngDetail.etcFileYn || accApplyPgmMngDetail.etcFile2Yn">
              <li class="form-row">
                <BaseLabelElement
                  :label="$t('content.accelerating.academy.OtherInfo.txt01')"
                  required
                />
                <!-- 사업자등록증 파일첨부 -->
                <template
                  v-if="accApplyPgmMngDetail.comRegNoFileYn">
                  <ValidationProvider
                    vid="001_file_001_com_reg_no_file_name"
                    :name="$t('content.accelerating.academy.OtherInfo.txt02')"
                    :rules="{ fieldRequired: true }"
                    v-slot="{ errors, failed }">
                    <FileInput
                      ref="001_file_001_com_reg_no_file_name"
                      v-model="accInfo.com_reg_no_file_name"
                      id="com_reg_no_file_name"
                      :placeholder="$t('content.accelerating.academy.OtherInfo.txt02')"
                      :accept="fileAccept"
                      :invalid="failed"
                      @change="selectFileComReg"
                      @remove="removeFileComReg"
                    />

                    <!-- 사업자등록증 파일첨부 유의사항 -->
                    <span class="input-desc">{{ accApplyPgmMngDetail.comRegNoFileNote }}</span>
                    <span class="input-error-desc">{{ errors[0] }}</span>
                  </ValidationProvider>
                </template>

                <!-- 주주명부 파일첨부 -->
                <template
                  v-if="accApplyPgmMngDetail.stockholderFileYn">
                  <ValidationProvider
                    vid="001_file_002_stockholder_file_name"
                    :name="$t('content.accelerating.academy.OtherInfo.txt03')"
                    :rules="{ fieldRequired: true }"
                    v-slot="{ errors, failed }">
                    <FileInput
                      ref="001_file_002_stockholder_file_name"
                      v-model="accInfo.stockholder_file_name"
                      id="stockholder_file_name"
                      :placeholder="$t('content.accelerating.academy.OtherInfo.txt03')"
                      :accept="fileAccept"
                      :invalid="failed"
                      @change="selectFileStockholder"
                      @remove="removeFileStockholder"
                    />

                    <!-- 주주명부 파일첨부 유의사항 -->
                    <span class="input-desc">{{ accApplyPgmMngDetail.stockholderFileNote }}</span>
                    <span class="input-error-desc">{{ errors[0] }}</span>
                  </ValidationProvider>
                </template>

                <!-- 등기부등본 파일첨부 -->
                <template
                  v-if="accApplyPgmMngDetail.registerFileYn">
                  <ValidationProvider
                    vid="001_file_003_register_file_name"
                    :name="$t('content.accelerating.academy.OtherInfo.txt04')"
                    :rules="{ fieldRequired: true }"
                    v-slot="{ errors, failed }">
                    <FileInput
                      ref="001_file_003_register_file_name"
                      v-model="accInfo.register_file_name"
                      id="register_file_name"
                      :placeholder="$t('content.accelerating.academy.OtherInfo.txt04')"
                      :accept="fileAccept"
                      :invalid="failed"
                      @change="selectFileRegister"
                      @remove="removeFileRegister"
                    />

                    <!-- 등기부등본 파일첨부 유의사항 -->
                    <span class="input-desc">{{ accApplyPgmMngDetail.registerFileNote }}</span>
                    <span class="input-error-desc">{{ errors[0] }}</span>
                  </ValidationProvider>
                </template>

                <!-- 지적재산권 사본 파일첨부 -->
                <template
                  v-if="accApplyPgmMngDetail.propertyFileYn">
                  <ValidationProvider
                    vid="001_file_004_property_file_name"
                    :name="$t('content.accelerating.academy.OtherInfo.txt06')"
                    :rules="{ fieldRequired: true }"
                    v-slot="{ errors, failed }">
                    <FileInput
                      ref="001_file_004_property_file_name"
                      v-model="accInfo.property_file_name"
                      id="property_file_name"
                      :placeholder="$t('content.accelerating.academy.OtherInfo.txt06')"
                      :accept="fileAccept"
                      :invalid="failed"
                      @change="selectFileProperty"
                      @remove="removeFileProperty"
                    />

                    <!-- 지적재산권 사본 파일첨부 유의사항 -->
                    <span class="input-desc">{{ accApplyPgmMngDetail.propertyFileNote }}</span>
                    <span class="input-error-desc">{{ errors[0] }}</span>
                  </ValidationProvider>
                </template>

                <!-- 기타1 파일첨부 -->
                <template
                  v-if="accApplyPgmMngDetail.etcFileYn">
                  <ValidationProvider
                    vid="001_file_005_etc_file_name"
                    :name="accApplyPgmMngDetail.etcFileTitle"
                    rules="required|size:etc_file_name,10"
                    v-slot="{ errors, failed }">
                    <FileInput
                      ref="001_file_005_etc_file_name"
                      v-model="accInfo.etc_file_name"
                      id="etc_file_name"
                      :placeholder="accApplyPgmMngDetail.etcFileTitle"
                      :accept="(accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1)? fileAcceptHackathon : fileAccept"
                      :invalid="failed"
                      @change="selectFileEtc"
                      @remove="removeFileEtc"
                    />

                    <!-- 기타1 파일첨부 유의사항 -->
                    <span class="input-desc">{{ accApplyPgmMngDetail.etcFileNote }}</span>
                    <span class="input-error-desc">{{ errors[0] }}</span>
                  </ValidationProvider>
                </template>

                <!-- 기타2 파일첨부 -->
                <template
                  v-if="accApplyPgmMngDetail.etcFile2Yn">
                  <ValidationProvider
                    vid="001_file_006_etc_file_name2"
                    :name="accApplyPgmMngDetail.etcFileTitle2"
                    :rules="{ fieldRequired: true }"
                    v-slot="{ errors, failed }">
                    <FileInput
                      ref="001_file_006_etc_file_name2"
                      v-model="accInfo.etc_file_name2"
                      id="etc_file_name2"
                      :placeholder="accApplyPgmMngDetail.etcFileTitle2"
                      :accept="fileAccept"
                      :invalid="failed"
                      @change="selectFileEtc2"
                      @remove="removeFileEtc2"
                    />

                    <!-- 기타2 파일첨부 유의사항 -->
                    <span class="input-desc">{{ accApplyPgmMngDetail.etcFileNote2 }}</span>
                    <span class="input-error-desc">{{ errors[0] }}</span>
                  </ValidationProvider>
                </template>
              </li>
              <li class="form-row no-space">
                <span class="input-desc">{{$t('content.accelerating.academy.OtherInfo.txt23')}}</span>
              </li>
            </template>
            <!-- // 파일첨부 -->

            <!-- 지원영역 : (AC102) Lifestyle-Digital Healthcare / Wellness인 경우 -->
            <li
              v-if="accApplyPgmMngDetail.accKind === 'AC102'">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.OtherInfo.txt22')"
                required
              />

              <ValidationProvider
                vid="001_acc_addition_kind"
                :name="$t('content.accelerating.academy.OtherInfo.txt22')"
                :rules="{ required: true }"
                v-slot="{ errors }"
                slim
              >
                <!-- Digital Healthcare -->
                <BaseRadioButton
                  ref="001_acc_addition_kind"
                  name="acc_addition_kind"
                  v-model="accInfo.acc_addition_kind"
                  value="0"
                  required
                >{{$t('content.accelerating.academy.OtherInfo.txt63')}}</BaseRadioButton>
                <!-- Wellness -->
                <BaseRadioButton
                  name="acc_addition_kind"
                  v-model="accInfo.acc_addition_kind"
                  value="1"
                  required
                >{{$t('content.accelerating.academy.OtherInfo.txt64')}}</BaseRadioButton>

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>
            <!-- // 지원영역 : (AC102) Lifestyle-Digital Healthcare / Wellness인 경우 -->

            <!-- 원하는 지원 항목 -->
            <li
              v-if="accApplyPgmMngDetail.codeSupportItemYn"
              class="form-row">
              <!-- 원하는 지원 항목 : (AC107) Corporate ACC-Eland인 경우 -->
              <template v-if="accApplyPgmMngDetail.accKind === 'AC107'">
                <BaseLabelElement
                  :label="$t('content.accelerating.academy.OtherInfo.txt07')"
                  required
                />

                <ValidationProvider
                  vid="002_code_support_corporateitem"
                  :name="$t('content.accelerating.academy.OtherInfo.txt07')"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  slim
                >
                  <!-- 리테일테크 -->
                  <BaseRadioButton
                    ref="002_code_support_corporateitem"
                    name="code_support_corporateitem"
                    v-model="accInfo.code_support_corporateitem"
                    value="1"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt24')}}</BaseRadioButton>

                  <!-- 신규 리테일 컨텐츠 -->
                  <!-- <BaseRadioButton
                    name="code_support_corporateitem"
                    v-model="accInfo.code_support_corporateitem"
                    value="2"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt25')}}</BaseRadioButton> -->

                  <!-- 신규 비즈니스 모델 -->
                  <!-- <BaseRadioButton
                    name="code_support_corporateitem"
                    v-model="accInfo.code_support_corporateitem"
                    value="3"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt26')}}</BaseRadioButton> -->

                  <!-- 컨텐츠 비즈니스 -->
                  <BaseRadioButton
                    name="code_support_corporateitem"
                    v-model="accInfo.code_support_corporateitem"
                    value="4"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt61')}}</BaseRadioButton>

                  <!-- 신규 플랫폼 -->
                  <BaseRadioButton
                    name="code_support_corporateitem"
                    v-model="accInfo.code_support_corporateitem"
                    value="5"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt62')}}</BaseRadioButton>

                  <span class="input-error-desc">{{ errors[0] }}</span>
                </ValidationProvider>
              </template>
              <!-- // 원하는 지원 항목 : (AC107) Corporate ACC-Eland인 경우 -->

              <!-- 원하는 지원 항목 : (AC108) GEP (Global Expansion Program)인 경우 -->
              <template v-else-if="accApplyPgmMngDetail.accKind === 'AC108'">
                <BaseLabelElement
                  :label="$t('content.accelerating.academy.OtherInfo.txt07')"
                  required
                />

                <ValidationProvider
                  vid="002_code_support_gepitem"
                  :name="$t('content.accelerating.academy.OtherInfo.txt22')"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  slim
                >
                  <!-- 중국 -->
                  <BaseRadioButton
                    ref="002_code_support_gepitem"
                    name="code_support_gepitem"
                    v-model="accInfo.code_support_gepitem"
                    value="1"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt28')}}</BaseRadioButton>

                  <!-- 베트남 -->
                  <BaseRadioButton
                    name="code_support_gepitem"
                    v-model="accInfo.code_support_gepitem"
                    value="2"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt29')}}</BaseRadioButton>

                  <!-- 일본 -->
                  <BaseRadioButton
                    name="code_support_gepitem"
                    v-model="accInfo.code_support_gepitem"
                    value="3"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt30')}}</BaseRadioButton>

                  <!-- 인도네시아 -->
                  <BaseRadioButton
                    name="code_support_gepitem"
                    v-model="accInfo.code_support_gepitem"
                    value="4"
                    required
                  >{{$t('content.accelerating.academy.OtherInfo.txt31')}}</BaseRadioButton>

                  <span class="input-error-desc">{{ errors[0] }}</span>
                </ValidationProvider>
              </template>
              <!-- // 원하는 지원 항목 : (AC108) GEP (Global Expansion Program)인 경우 -->

              <!-- 원하는 지원 항목 : (AC114) Corporate ACC-Hyundai인 경우 -->
              <template v-else-if="accApplyPgmMngDetail.accKind === 'AC114'">
                <BaseLabelElement
                  :label="$t('content.accelerating.academy.OtherInfo.txt07')"
                  required
                />

                <!-- 현대자동차그룹 및 한화 계열사 현업 팀과의 희망 협업 프로젝트를 선택하여 지원 (테마는 1개 선택, 프로젝트는 복수 선택 가능) -->
                <span class="input-desc">{{$t('content.accelerating.academy.OtherInfo.txt65')}}</span>

                <!-- Step 1 : 테마 -->
                <ValidationProvider
                  vid="002_code_support_item"
                  :name="$t('content.accelerating.academy.OtherInfo.txt07')"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  slim
                >
                  <ul class="form-list-sm">
                    <!-- HEALTH MONITORING -->
                    <li>
                      <BaseRadioButton
                        ref="002_code_support_item"
                        name="code_support_item"
                        v-model="accSupportItemAc114Step1"
                        :value="0"
                        required
                      >{{$t('content.accelerating.academy.OtherInfo.txt32')}}</BaseRadioButton>
                    </li>

                    <!-- HEALTH DATA ANALYTICS -->
                    <li>
                      <BaseRadioButton
                        name="code_support_item"
                        v-model="accSupportItemAc114Step1"
                        :value="1"
                        required
                      >{{$t('content.accelerating.academy.OtherInfo.txt33')}}</BaseRadioButton>
                    </li>

                    <!-- IN-CAR CARE SOLUTION -->
                    <li>
                      <BaseRadioButton
                        name="code_support_item"
                        v-model="accSupportItemAc114Step1"
                        :value="2"
                        required
                      >{{$t('content.accelerating.academy.OtherInfo.txt34')}}</BaseRadioButton>
                    </li>

                    <!-- CONNECTED CARE SERVICE -->
                    <li>
                      <BaseRadioButton
                        name="code_support_item"
                        v-model="accSupportItemAc114Step1"
                        :value="3"
                        required
                      >{{$t('content.accelerating.academy.OtherInfo.txt35')}}</BaseRadioButton>
                    </li>
                  </ul>

                  <span class="input-error-desc">{{ errors[0] }}</span>
                </ValidationProvider>
                <!-- // Step 1 : 테마 -->

                <!-- Step 2 : 프로젝트 - HEALTH MONITORING -->
                <ul
                  v-if="accSupportItemAc114Step1 === 0"
                  class="form-list-sm">
                  <!-- 운전자 감정/건강 정보 수집 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[0]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt36')}}</BaseCheckbox>
                  </li>

                  <!-- 외부 환경 정보 수집 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[1]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt37')}}</BaseCheckbox>
                  </li>
                </ul>
                <!-- // Step 2 : 프로젝트 - HEALTH MONITORING -->

                <!-- Step 2 : 프로젝트 - HEALTH DATA ANALYTICS -->
                <ul
                  v-if="accSupportItemAc114Step1 === 1"
                  class="form-list-sm">
                  <!-- 식단 분석 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[0]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt38')}}</BaseCheckbox>
                  </li>

                  <!-- 영양소 분석 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[1]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt39')}}</BaseCheckbox>
                  </li>

                  <!-- HEART RATE -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[2]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt40')}}</BaseCheckbox>
                  </li>

                  <!-- BODY MASS -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[3]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt41')}}</BaseCheckbox>
                  </li>

                  <!-- 혈당측정/분석 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[4]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt42')}}</BaseCheckbox>
                  </li>

                  <!-- 수면 분석 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[5]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt43')}}</BaseCheckbox>
                  </li>
                </ul>
                <!-- // Step 2 : 프로젝트 - HEALTH DATA ANALYTICS -->

                <!-- Step 2 : 프로젝트 - IN-CAR CARE SOLUTION -->
                <ul
                  v-if="accSupportItemAc114Step1 === 2"
                  class="form-list-sm">
                  <!-- 건강 테라피 콘텐츠 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[0]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt44')}}</BaseCheckbox>
                  </li>

                  <!-- 심리 상담 및 케어 기능 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[1]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt45')}}</BaseCheckbox>
                  </li>

                  <!-- 감정 상태 맞춤형 실내 환경 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[2]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt46')}}</BaseCheckbox>
                  </li>

                  <!-- 장애 보조 실내 기능 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[3]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt47')}}</BaseCheckbox>
                  </li>

                  <!-- 운동/자세 보조 기능 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[4]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt48')}}</BaseCheckbox>
                  </li>
                </ul>
                <!-- // Step 2 : 프로젝트 - IN-CAR CARE SOLUTION -->

                <!-- Step 2 : 프로젝트 - CONNECTED CARE SERVICE -->
                <ul
                  v-if="accSupportItemAc114Step1 === 3"
                  class="form-list-sm">
                  <!-- 병원/약국 접근성 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[0]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt49')}}</BaseCheckbox>
                  </li>

                  <!-- 맞춤형 명소 추천/안내 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[1]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt50')}}</BaseCheckbox>
                  </li>

                  <!-- 보험 연계 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItemAc114Step2[2]"
                    >{{$t('content.accelerating.academy.OtherInfo.txt51')}}</BaseCheckbox>
                  </li>
                </ul>
                <!-- // Step 2 : 프로젝트 - CONNECTED CARE SERVICE -->
              </template>
              <!-- // 원하는 지원 항목 : (AC114) Corporate ACC-Hyundai인 경우 -->

              <!-- 원하는 지원 항목 : (AC115) Corporate Eisai인 경우 -->
              <template v-else-if="accApplyPgmMngDetail.accKind === 'AC115'">
                <BaseLabelElement
                  :label="$t('content.accelerating.academy.OtherInfo.txt07')"
                />

                <ul class="form-list-sm">
                  <!-- 인지기능 스크리닝 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItem[0]"
                      >{{$t('content.accelerating.academy.OtherInfo.txt66')}}</BaseCheckbox>
                  </li>
                  <!-- 인지기능 트레이닝 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItem[1]"
                      >{{$t('content.accelerating.academy.OtherInfo.txt67')}}</BaseCheckbox>
                  </li>
                  <!-- 식이요법 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItem[2]"
                      >{{$t('content.accelerating.academy.OtherInfo.txt68')}}</BaseCheckbox>
                  </li>
                  <!-- 운동요법 -->
                  <li>
                    <BaseCheckbox
                      v-model="accSupportItem[3]"
                      >{{$t('content.accelerating.academy.OtherInfo.txt69')}}</BaseCheckbox>
                  </li>
                </ul>
              </template>
              <!-- // 원하는 지원 항목 : (AC115) Corporate Eisai인 경우 -->

              <!-- 원하는 지원 항목 : 그 이외의 일반적인 경우 -->
              <template v-else>
                <BaseLabelElement
                  :label="$t('content.accelerating.academy.OtherInfo.txt07')"
                  :required="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                />

                <ul class="form-list-sm">
                  <!-- 법무지원(각종 법률검토, 특허출원 등) -->
                  <template v-if="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1">

                    <li>
                      <!-- <BaseCheckbox
                        v-model="accSupportItem[0]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt70')}}</BaseCheckbox> -->
                      <BaseRadioButton
                        name="accSupportItem"
                        v-model="accSupporRadio"
                        value="0"
                      >{{$t('content.accelerating.academy.OtherInfo.txt70')}}</BaseRadioButton>
                    </li>
                    <li>
                      <!-- <BaseCheckbox
                        v-model="accSupportItem[5]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt75')}}</BaseCheckbox> -->
                      <BaseRadioButton
                        name="accSupportItem"
                        v-model="accSupporRadio"
                        value="5"
                      >{{$t('content.accelerating.academy.OtherInfo.txt75')}}</BaseRadioButton>
                    </li>
                    <li>
                      <!-- <BaseCheckbox
                        v-model="accSupportItem[8]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt78')}}</BaseCheckbox> -->
                      <BaseRadioButton
                        name="accSupportItem"
                        v-model="accSupporRadio"
                        value="8"
                      >{{$t('content.accelerating.academy.OtherInfo.txt78')}}</BaseRadioButton>
                    </li>
                    <li>
                      <!-- <BaseCheckbox
                        v-model="accSupportItem[9]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt79')}}</BaseCheckbox> -->
                      <BaseRadioButton
                        name="accSupportItem"
                        v-model="accSupporRadio"
                        value="9"
                      >{{$t('content.accelerating.academy.OtherInfo.txt79')}}</BaseRadioButton>
                    </li>
                  </template>
                  <template v-else>
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[0]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt08')}}</BaseCheckbox>
                    </li>
                    <!-- 세무/회계 -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[1]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt09')}}</BaseCheckbox>
                    </li>
                    <!-- 홍보/마케팅 -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[2]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt10')}}</BaseCheckbox>
                    </li>
                    <!-- 개발 검수/지원 -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[3]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt11')}}</BaseCheckbox>
                    </li>
                    <!-- 해외진출(아시아 위주) -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[4]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt12')}}</BaseCheckbox>
                    </li>
                    <!-- 금융사 연계 등의 사업 지원 -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[5]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt13')}}</BaseCheckbox>
                    </li>
                    <!-- BM 강화 -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[6]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt14')}}</BaseCheckbox>
                    </li>
                    <!-- 투자 유치 -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[7]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt15')}}</BaseCheckbox>
                    </li>
                    <!-- 사무공간 -->
                    <li>
                      <BaseCheckbox
                        v-model="accSupportItem[8]"
                        >{{$t('content.accelerating.academy.OtherInfo.txt16')}}</BaseCheckbox>
                    </li>
                    </template>
                </ul>
                <span class='input-error-desc' v-if='warningApply'>지원항목을 입력해주세요</span>
              </template>
              <!-- // 원하는 지원 항목 : 그 이외의 일반적인 경우 -->
            </li>
            <!-- // 원하는 지원 항목 -->

            <!-- 지원동기 및 포부 -->
            <!-- * (AC107) Corporate ACC-Eland인 경우에는 : 지원동기 및 포부 필수입력 -->
            <li
              v-if="accApplyPgmMngDetail.supportPlanYn"
              class="form-row">
              <ValidationProvider
                vid="003_support_plan"
                :name="$t('content.accelerating.academy.OtherInfo.txt17')"
                :rules="{ required: accApplyPgmMngDetail.accKind === 'AC107' }"
                v-slot="{ errors, failed }"
                slim
              >
                <BaseTextarea
                  ref="003_support_plan"
                  id="support_plan"
                  v-model="accInfo.support_plan"
                  :label="$t('content.accelerating.academy.OtherInfo.txt17')"
                  :placeholder="$t('content.accelerating.academy.OtherInfo.txt18')"
                  maxlength="1000"
                  :required="accApplyPgmMngDetail.accKind === 'AC107'"
                  :invalid="failed"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 지원경로 -->
            <li
              v-if="accApplyPgmMngDetail.supportPathYn"
              class="form-row">
              <ValidationProvider
                vid="004_support_path"
                :name="$t('content.accelerating.academy.OtherInfo.txt52')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseLabelElement
                  ref="004_support_path"
                  :label="$t('content.accelerating.academy.OtherInfo.txt52')"
                  required
                />
                <!-- 지원경로 선택 -->
                <div
                  class="input-container select-container"
                  :class="{ error: failed }"
                >
                  <select
                    v-model="accInfo.support_path"
                    required
                  >
                    <option value="" disabled="disabled" selected="selected">
                      {{$t('content.accelerating.academy.OtherInfo.txt53')}}
                    </option>
                    <!-- SNS -->
                    <option value="AC401">{{$t('content.accelerating.academy.OtherInfo.txt54')}}</option>
                    <!-- 스타트업 매체 -->
                    <option value="AC402">{{$t('content.accelerating.academy.OtherInfo.txt55')}}</option>
                    <!-- 오프라인 포스터 -->
                    <option value="AC403">{{$t('content.accelerating.academy.OtherInfo.txt56')}}</option>
                    <!-- 공모전 사이트 -->
                    <option value="AC406">{{$t('content.accelerating.academy.OtherInfo.txt590')}}</option>
                    <!-- 외부추천 -->
                    <option value="AC404">{{$t('content.accelerating.academy.OtherInfo.txt57')}}</option>
                    <!-- 기타 -->
                    <option value="AC405">{{$t('content.accelerating.academy.OtherInfo.txt58')}}</option>
                  </select>
                </div>

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- 외부추천, 기타 지원경로 (직접입력) -->
              <template
                v-if='accInfo.support_path === "AC404" || accInfo.support_path === "AC405"'
              >
                <ValidationProvider
                  vid="004_support_path_sub"
                  :name="supportPathSubPlaceholder"
                  :rules="{ required: true }"
                  v-slot="{ errors, failed }">
                  <BaseInput
                    ref="004_support_path_sub"
                    id="support_path_sub"
                    v-model="accInfo.support_path_sub"
                    :placeholder="supportPathSubPlaceholder"
                    :invalid="failed"
                  />

                  <span class="input-error-desc">{{ errors[0] }}</span>
                </ValidationProvider>
              </template>
            </li>
            <!-- // 지원경로 -->

            <!-- 기타 입력1 -->
            <!-- * (AC107) Corporate ACC-Eland인 경우에는 : 기타 입력1 필수입력 -->
            <li
              v-if="accApplyPgmMngDetail.etcInputYn"
              class="form-row">
              <ValidationProvider
                vid="005_etc_input"
                :name="accApplyPgmMngDetail.etcInputTitle"
                :rules="{ required: accApplyPgmMngDetail.accKind === 'AC107' ||
                 accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1 }"
                v-slot="{ errors, failed }"
                slim
              >
                <BaseTextarea
                  ref="005_etc_input"
                  id="etc_input"
                  v-model="accInfo.etc_input"
                  :label="accApplyPgmMngDetail.etcInputTitle"
                  :placeholder="$t('content.accelerating.academy.OtherInfo.txt19')"
                  maxlength="1000"
                  :required="accApplyPgmMngDetail.accKind === 'AC107' ||
                   accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                  :invalid="failed"
                />

                <!-- 기타 입력1 유의사항 -->
                <span class="input-desc">{{ accApplyPgmMngDetail.etcInputNote }}</span>
                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 기타 입력2 -->
            <li
              v-if="accApplyPgmMngDetail.etcInput2Yn"
              class="form-row">
              <ValidationProvider
                vid="005_etc_input2"
                :name="accApplyPgmMngDetail.etcInputTitle2"
                :rules="{ required: accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1 }"
                v-slot="{ errors, failed }"
                slim
              >
              <BaseTextarea
                ref="005_etc_input2"
                id="etc_input2"
                v-model="accInfo.etc_input2"
                :label="accApplyPgmMngDetail.etcInputTitle2"
                :placeholder="$t('content.accelerating.academy.OtherInfo.txt19')"
                maxlength="1000"
                :required="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                :invalid="failed"
              />

              <!-- 기타 입력2 유의사항 -->
              <span class="input-desc">{{ accApplyPgmMngDetail.etcInputNote2 }}</span>
              <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>
            <template v-if="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1">
              <li
                v-if="accApplyPgmMngDetail.etcInput3Yn"
                class="form-row">
                <ValidationProvider
                vid="005_etc_input3"
                :name="accApplyPgmMngDetail.etcInputTitle3"
                :rules="{ required: accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1 }"
                v-slot="{ errors, failed }"
                slim
               >
                <BaseTextarea
                  ref="005_etc_input3"
                  id="etc_input3"
                  v-model="accInfo.etc_input3"
                  :label="accApplyPgmMngDetail.etcInputTitle3"
                  :placeholder="$t('content.accelerating.academy.OtherInfo.txt19')"
                  :required="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                  maxlength="1000"
                  :invalid="failed"
                />

                <!-- 기타 입력3 유의사항 -->
                <span class="input-desc">{{ accApplyPgmMngDetail.etcInputNote3 }}</span>
                <span class="input-error-desc">{{ errors[0] }}</span>
               </ValidationProvider>
              </li>

              <li
                v-if="accApplyPgmMngDetail.etcInput4Yn"
                class="form-row">
                <ValidationProvider
                vid="005_etc_input4"
                :name="accApplyPgmMngDetail.etcInputTitle4"
                :rules="{ required: accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1 }"
                v-slot="{ errors, failed }"
                slim
               >

                <BaseTextarea
                  ref="005_etc_input4"
                  id="etc_input4"
                  v-model="accInfo.etc_input4"
                  :label="accApplyPgmMngDetail.etcInputTitle4"
                  :placeholder="$t('content.accelerating.academy.OtherInfo.txt19')"
                  maxlength="1000"
                  :required="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                  :invalid="failed"
                />

                <!-- 기타 입력4 유의사항 -->
                <span class="input-desc">{{ accApplyPgmMngDetail.etcInputNote4 }}</span>
                <span class="input-error-desc">{{ errors[0] }}</span>
               </ValidationProvider>
              </li>

              <li
                v-if="accApplyPgmMngDetail.etcInput5Yn"
                class="form-row">
                <ValidationProvider
                vid="005_etc_input5"
                :name="accApplyPgmMngDetail.etcInputTitle5"
                :rules="{ required: accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1 }"
                v-slot="{ errors, failed }"
                slim
               >

                <BaseTextarea
                  ref="005_etc_input5"
                  id="etc_input5"
                  v-model="accInfo.etc_input5"
                  :label="accApplyPgmMngDetail.etcInputTitle5"
                  :placeholder="$t('content.accelerating.academy.OtherInfo.txt19')"
                  maxlength="1000"
                  :required="accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                  :invalid="failed"
                />

                <!-- 기타 입력5 유의사항 -->
                <span class="input-desc">{{ accApplyPgmMngDetail.etcInputNote5 }}</span>
                <span class="input-error-desc">{{ errors[0] }}</span>
               </ValidationProvider>
              </li>

            </template>

            <!-- 프로그램 참가 서약서 -->
            <li
              v-if="accApplyPgmMngDetail.entryAgreeYn"
              class="form-row">
              <ValidationProvider
                vid="007_entry_agree"
                :name="$t('content.accelerating.academy.OtherInfo.txt20')"
                :rules="{ oneOf: ['1'] }"
              >
                <BaseLabelElement
                  :label="$t('content.accelerating.academy.OtherInfo.txt20')"
                  required
                />
                <div class="terms-area">
                  <!-- 프로그램 참가서약서 내용 -->
                  <div class="terms-container">
                    <dl>
                      <dt>{{$t('content.accelerating.academy.OtherInfo.txt20')}}</dt>
                      <dd
                        class="grey"
                        v-html="accApplyPgmMngDetail.entryAgreeContents">
                      </dd>
                    </dl>
                  </div>

                  <!-- 프로그램 참가서약서 동의 -->
                  <BaseCheckbox
                    ref="007_entry_agree"
                    id="entry_agree"
                    v-model="accInfo.entry_agree"
                    trueValue="1"
                    falseValue="0"
                    required
                    >{{$t('content.accelerating.academy.OtherInfo.txt21')}}</BaseCheckbox>
                </div>
              </ValidationProvider>
            </li>
            <!-- // 프로그램 참가 서약서 -->
          </ul>
        </fieldset>

        <!-- 버튼 -->
        <div class="btn-wrap type2">
          <BackButton
            @click="$emit('back')"
          />
          <BaseButton
            type="line"
            @click="$emit('save')"
          >{{$t('content.common.button.btn01')}}</BaseButton>
          <BaseButton
            type="line"
            @click="doPrev"
          >{{$t('content.common.button.btn03')}}</BaseButton>
          <BaseButton
            @click="doNext"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>
        <!-- // 버튼 -->
      </form>
      <!-- // 기타입력 폼 -->
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { FilePaths } from '@/common/portalApi';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseTextarea from '@/components/base/BaseTextarea.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BackButton from '@/components/base/BackButton.vue';
import FileInput from '@/components/base/FileInput.vue';

extend('size', {
  params: ['id', 'size'],
  validate(val, { id, size }) {
    const el = document.getElementById(id);
    if (el && el.files && el.files.length > 0) {
      // check megabyte
      const fileSize = el.files[0].size / 1024 / 1024;
      console.log(fileSize);
      if (fileSize < size) {
        return true;
      }
    } else if (el && el.files && el.files.length === 0) {
      return true;
    }
    return false;
  },
});

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) - 5단계 : 기타입력
 */
export default {
  name: 'ProgramsApplyStep5',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseLabelElement,
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
    BaseRadioButton,
    BaseButton,
    FileInput,
    BackButton,
  },

  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('../dto/AccApplyPgmMngDetailDto').default}
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },

    /**
     * Acc지원 정보 - 지원정보 (회사정보, 서비스, 기타입력 등)
     * @type {import('../dto/apply/AccInfoDto').default} AccInfoDto
     * @see AccApplyDto
     */
    accInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    /**
     * 원하는 지원 항목 : accInfo.code_support_item(하나의 문자열)을 배열 형태로 변환
     */
    const [item1, item2, item3, item4, item5, item6, item7, item8, item9, item10] = this
      .accInfo.code_support_item.split('');
    const adjustedAccSupportItem = [
      item1 === '1',
      item2 === '1',
      item3 === '1',
      item4 === '1',
      item5 === '1',
      item6 === '1',
      item7 === '1',
      item8 === '1',
      item9 === '1',
      item10 === '1',
    ];

    /**
     * 원하는 지원 항목 (Step 2) : (AC114) Corporate ACC-Hyundai인 경우
     *   accInfo.code_support_corporateitem(하나의 문자열)을 배열 형태로 변환
     */
    const [acc114Item1, acc114Item2, acc114Item3, acc114Item4, acc114Item5, acc114Item6, acc114Item7,
      acc114Item8, acc114Item9] = (this.accInfo.code_support_corporateitem || '').split('');
    const adjustedCodeSupportCorporateitemAc114 = [
      acc114Item1 === '1',
      acc114Item2 === '1',
      acc114Item3 === '1',
      acc114Item4 === '1',
      acc114Item5 === '1',
      acc114Item6 === '1',
      acc114Item7 === '1',
      acc114Item8 === '1',
      acc114Item9 === '1',
    ];

    return {
      /**
       * 원하는 지원 항목
       * @type {boolean[]}
       */
      accSupportItem: adjustedAccSupportItem,
      warningApply: false,
      /**
       * 원하는 지원 항목 (Step 1) : (AC114) Corporate ACC-Hyundai인 경우
       * @type {number}
       */
      accSupportItemAc114Step1: adjustedAccSupportItem.findIndex((item) => item),

      /**
       * 원하는 지원 항목 (Step 2) : (AC114) Corporate ACC-Hyundai인 경우
       * @type {boolean[]}
       */
      accSupportItemAc114Step2: adjustedCodeSupportCorporateitemAc114,

      /**
       * 첨부가능한 파일유형
       * @type {string}
       */
      fileAccept: '.xls, .doc, .ppt, .txt, .pdf, .hwp, .xlsx, .docx, .pptx, .jpg, .jpeg, .png, .gif, .ico, .bmp',
      fileAcceptHackathon: '.pdf',
      accSupporRadio: this.accInfo.code_support_item.indexOf(1) || 0,
    };
  },

  computed: {
    supportPathSubPlaceholder() {
      let placeholder = '';
      if (this.accInfo.support_path === 'AC404') {
        // 외부추천
        placeholder = this.$t('content.accelerating.academy.OtherInfo.txt59');
      } else if (this.accInfo.support_path === 'AC405') {
        // 기타
        placeholder = this.$t('content.accelerating.academy.OtherInfo.txt60');
      }
      return placeholder;
    },
  },
  watch: {
    /**
     * 원하는 지원 항목 : 변경된 배열을 accInfo.code_support_item(하나의 문자열)로 변환
     * @see https://vuejs.org/v2/api/index.html#watch
     */
    accSupportItem: {
      deep: true,
      handler(newVal) {
        if (this.accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1) {
          if (this.accSupportItem.some((checked) => checked === true)) {
            this.warningApply = false;
          } else {
            this.warningApply = true;
          }
        }
        this.accInfo.code_support_item = newVal
          .reduce(
            (p, value) => `${p}${value ? '1' : '0'}`,
            '',
          );
      },
    },
    accSupporRadio(newVal) {
      let accSupport = '';
      for (let i = 0; i < 10; i += 1) {
        if (i === newVal * 1) {
          accSupport += '1';
        } else {
          accSupport += '0';
        }
      }
      this.accInfo.code_support_item = accSupport;
      this.warningApply = false;
    },

    /**
     * 원하는 지원 항목 (Step 1) : (AC114) Corporate ACC-Hyundai인 경우
     *   변경된 값을 accInfo.code_support_item(하나의 문자열)로 변환
     */
    accSupportItemAc114Step1(newVal) {
      this.accInfo.code_support_item = this.accInfo.code_support_item.split('')
        .reduce(
          (p, value, index) => `${p}${index === newVal ? '1' : '0'}`,
          '',
        );

      // Step 2 선택 초기화
      this.accSupportItemAc114Step2 = this.accSupportItemAc114Step2.map(() => false);
    },

    /**
     * 원하는 지원 항목 (Step 2) : (AC114) Corporate ACC-Hyundai인 경우
     *   변경된 값을 accInfo.code_support_item(하나의 문자열)로 변환
     */
    accSupportItemAc114Step2: {
      deep: true,
      handler(newVal) {
        this.accInfo.code_support_corporateitem = newVal
          .reduce(
            (p, value) => `${p}${value ? '1' : '0'}`,
            '',
          );
      },
    },
  },

  /**
   * beforeRouteEnter
   * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards In-Component Guards}
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.accApplyPgmMngDetail.isEmptyStep5()) {
        // 입력할 것이 없으면 스텝 건너뛰기
        if (from && from.name === 'ProgramsApplyStep6') {
          vm.doPrev();
        } else {
          vm.doNext();
        }
      }
    });
  },

  methods: {
    doNext() {
      this.validateForm(this.$refs.form) // mixinHelperUtils
        .then(() => {
          if (this.accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1) {
            this.$router.push({
              name: 'ProgramsApplyStep6',
            });
          } else {
            this.$router.push({
              name: 'ProgramsApplyStep6',
            });
          }
        }).catch(() => {
          if (this.accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1) {
            if (!this.accSupportItem.some((checked) => checked === true)) {
              this.warningApply = true;
            }
          }
        });
    },
    doPrev() {
      this.$router.push({
        name: 'ProgramsApplyStep4',
      });
    },

    /**
     * 기타입력 첨부파일 업로드
     */
    uploadEtcInfoFile(file, filePropName, fileNamePropName) {
      const { acc_pgm_mng_no: accPgmMngNo, acc_no: accNo } = this.accInfo;
      const fileName = `${accPgmMngNo}-${accNo}-${this.uuid()}.${file.name.split('.').slice(-1)}`;
      this.uploadFile(FilePaths.ACCELERATING, fileName, file.type, file)
        .then(() => {
          this.accInfo[filePropName] = fileName;
          this.accInfo[fileNamePropName] = file.name;
        })
        .catch(() => {
          this.accInfo[filePropName] = undefined;
          this.accInfo[fileNamePropName] = undefined;
        });
    },
    /**
     * 기타입력 첨부파일 삭제
     */
    removeEtcInfoFile(filePropName, fileNamePropName) {
      const fileName = this.accInfo[filePropName];
      this.removeFile(FilePaths.ACCELERATING, fileName)
        .then(() => {
          this.accInfo[filePropName] = undefined;
          this.accInfo[fileNamePropName] = undefined;
        })
        .catch(() => {
        });
    },

    selectFileComReg(file) {
      this.uploadEtcInfoFile(file, 'com_reg_no_file', 'com_reg_no_file_name');
    },
    removeFileComReg() {
      this.removeEtcInfoFile('com_reg_no_file', 'com_reg_no_file_name');
    },
    selectFileStockholder(file) {
      this.uploadEtcInfoFile(file, 'stockholder_file', 'stockholder_file_name');
    },
    removeFileStockholder() {
      this.removeEtcInfoFile('stockholder_file', 'stockholder_file_name');
    },
    selectFileRegister(file) {
      this.uploadEtcInfoFile(file, 'register_file', 'register_file_name');
    },
    removeFileRegister() {
      this.removeEtcInfoFile('register_file', 'register_file_name');
    },
    selectFileProperty(file) {
      this.uploadEtcInfoFile(file, 'property_file', 'property_file_name');
    },
    removeFileProperty() {
      this.removeEtcInfoFile('property_file', 'property_file_name');
    },
    selectFileEtc(file) {
      this.uploadEtcInfoFile(file, 'etc_file', 'etc_file_name');
    },
    removeFileEtc() {
      this.removeEtcInfoFile('etc_file', 'etc_file_name');
    },
    selectFileEtc2(file) {
      this.uploadEtcInfoFile(file, 'etc_file2', 'etc_file_name2');
    },
    removeFileEtc2() {
      this.removeEtcInfoFile('etc_file2', 'etc_file_name2');
    },
  },
};
</script>
